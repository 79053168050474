<template>
  <b-container>
    <b-row v-if="!loading">
      <b-col>
        <b-card>
          <b-container>
            <b-row>
              <b-col>
          <p><b-link :to="{ name: 'Regbites' }">back to regulatory quotes</b-link></p>
          <h2>
            <b-button class="mr-2" v-if="permission" variant="outline-primary" :to="{ name: 'RegbiteEdit', params: { id: row.id }}">Edit</b-button>
            {{row.name}}
          </h2>
          <div><markdown :content="row.comments" /></div>
          <div><strong>Source:</strong></div>
          <span v-for="source in extsources" :key="source.id">
            <div><router-link :to="{ name: 'Extsource', params: { id: source.id }}">{{source.name}}</router-link></div>
          </span>
          <div><strong>Risks:</strong></div>
          <span v-for="risk in risks" :key="risk.id">
            <div><router-link :to="{ name: 'Risk', params: { id: risk.id }}">{{risk.name}}</router-link></div>
          </span>
              </b-col>
            </b-row>

          </b-container>
        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import _ from 'lodash'
import ac from '../libs/accesscontrol'
import Markdown from '@/components/Markdown.vue'

export default {
  name: 'Regbite',
  beforeDestroy () {
  },
  components: {
    Markdown
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'regbite', action: 'open regbite', mode: 'regbite', model_id: this.$route.params.id })
    this.permission = ac.can(this.user.acgroups).createAny('extsource').granted
    this.loadRows()
  },
  data () {
    return {
      extsources: '',
      loading: true,
      permission: false,
      risks: '',
      row: ''
    }
  },
  methods: {
    loadRows: async function () {
      this.$logger.debug('loadRisks started')
      this.loading = true
      try {
        let apiName = 'cosmos'
        let path = `/regbite/${this.$route.params.id}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.row = response
      } catch (e) {
        this.$logger.warn('/regbite/:id error ', e)
      }
      // get regulation
      try {
        let apiName = 'cosmos'
        // let path = `/vertex/${this.$route.params.id}/events/edge/out/inExtsource/vertices`
        let path = `/standard/relation/extsource/${this.$route.params.id}/regbites/regbites.id/extsources.del`
        let response2 = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('loadEdges OK', response2)
        this.extsources = _.sortBy(response2, ['name'])
      } catch (e) {
        this.$logger.warn('error getting extsources', e)
      }
      // get risks
      try {
        let apiName = 'cosmos'
        // let path = `/vertex/${this.$route.params.id}/events/edge/out/defines/vertices`
        let path = `/standard/relation/risk/${this.$route.params.id}/regbites/regbites.id/risks.del`
        let response3 = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('loadEdges OK', response3)
        this.risks = _.sortBy(response3, ['name'])
        this.loading = false
      } catch (e) {
        this.$logger.warn('error getting extsources', e)
      }
    }
  },
  mounted () {
  },
  watch: {
  }
}
</script>

<style>
</style>
